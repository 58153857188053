import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import loadable from "@loadable/component";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import { UserType } from "./utils/UserType";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Context
import AuthContext from "./context/AuthContext";
import DiscussionContextProvider from "./context/DiscussionContextProvider";
import SessionContextProvider from "./context/SessionContextProvider";
import NotFound from "./pages/NotFound";

const Home = loadable(() => import("./pages/Home"), {
  fallback: (
    <div className="w-screen h-[60vh] ss:h-screen flex justify-center items-center bg-bkg dark:bg-content"></div>
  ),
});
const Sidebar = loadable(() => import("./components/Sidebar"), {
  fallback: (
    <div className="w-[5vw] h-[94vh] md:h-[90vh] bg-bkg dark:bg-content"></div>
  ),
});

// Normal user
const Preregistration = loadable(() => import("./pages/Preregistration"), {
  fallback: <div className="h-screen bg-bkg dark:bg-content w-screen"></div>,
});
const SignIn = loadable(() => import("./pages/Signin"), {
  fallback: <div className="h-[90vh] bg-bkg dark:bg-content w-screen"></div>,
});
const ForgotPassword = loadable(() => import("./pages/ForgotPassword"), {
  fallback: <div className="h-screen w-screen bg-bkg dark:bg-content"></div>,
});
const SignUpStudent = loadable(() => import("./pages/SignupStudent"), {
  fallback: <div className="h-screen w-full bg-bkg dark:bg-content"></div>,
});
const SignUpProf = loadable(() => import("./pages/SignupProf"), {
  fallback: <div className="h-screen bg-bkg dark:bg-content w-screen"></div>,
});
const ChangePassword = loadable(() => import("./pages/ChangePassword"), {
  fallback: <div className='h-screen w-screen bg-bkg dark:bg-content"'></div>,
});
const CourseDetails = loadable(() => import("./pages/CourseDetails"), {
  fallback: <div className="bg-bkg dark:bg-content w-full h-screen"></div>,
});
const AllProfs = loadable(() => import("./pages/AllProfs"), {
  fallback: (
    <div className="min-h-screen bg-bkg dark:bg-content w-screen"></div>
  ),
});
const ProfsBySubject = loadable(() => import("./pages/ProfsBySubject"), {
  fallback: <div className="min-h-screen bg-bkg dark:bg-content"></div>,
});
const Subjects = loadable(() => import("./pages/Subjects"), {
  fallback: (
    <div className="min-h-screen bg-neutral-100 dark:bg-content w-screen"></div>
  ),
});
const Courses = loadable(() => import("./pages/Courses"), {
  fallback: (
    <div className="min-h-screen bg-bkg dark:bg-content w-screen"></div>
  ),
});
const ProfDetails = loadable(() => import("./pages/ProfDetails"), {
  fallback: (
    <div className="w-screen min-h-screen bg-bkg dark:bg-content"></div>
  ),
});

const Conditions = loadable(() => import("./pages/Conditions"), {
  fallback: (
    <div className="w-screen min-h-screen bg-bkg dark:bg-content"></div>
  ),
});
const ConditionsVentes = loadable(() => import("./pages/ConditionsVentes"), {
  fallback: (
    <div className="w-screen min-h-screen bg-bkg dark:bg-content"></div>
  ),
});

//Prof
const ProfCalendrier = loadable(
  () => import("./pages/ProfessorPages/ProfCalendrier"),
  {
    fallback: (
      <div className="md:min-w-[79vw] md:max-w-[95vw] h-[94vh] md:h-[90vh] max-h-[94vh] bg-bkg dark:bg-content"></div>
    ),
  }
);
const ProfCourses = loadable(
  () => import("./pages/ProfessorPages/ProfCourses"),
  {
    fallback: (
      <div className="md:min-w-[79vw] md:max-w-[95vw] h-[94vh] md:h-[90vh] max-h-[94vh] bg-bkg dark:bg-content"></div>
    ),
  }
);
const ProfPortefeuille = loadable(
  () => import("./pages/ProfessorPages/ProfPortefeuille"),
  {
    fallback: (
      <div className="md:min-w-[79vw] md:max-w-[95vw] h-[94vh] md:h-[90vh] max-h-[94vh] bg-bkg dark:bg-content"></div>
    ),
  }
);
const ProfessorProfil = loadable(
  () => import("./pages/ProfessorPages/ProfessorProfil"),
  {
    fallback: (
      <div className="dark:bg-content bg-bkg md:min-w-[79vw] md:max-w-[95vw] h-[94vh] md:h-[90vh] max-h-[94vh]"></div>
    ),
  }
);
const ProfessorDiscussion = loadable(
  () => import("./pages/ProfessorPages/ProfessorDiscussion"),
  {
    fallback: (
      <div className="md:min-w-[79vw] md:max-w-[95vw] h-[94vh] md:h-[90vh] max-h-[94vh] bg-bkg dark:bg-content"></div>
    ),
  }
);

//Student
const StudentFactures = loadable(
  () => import("./pages/StudentPages/StudentFactures"),
  {
    fallback: (
      <div className="md:min-w-[79vw] md:max-w-[95vw] h-[94vh] md:h-[90vh] max-h-[94vh] bg-bkg dark:bg-content"></div>
    ),
  }
);
const StudentFavorites = loadable(
  () => import("./pages/StudentPages/StudentFavorites"),
  {
    fallback: (
      <div className="md:min-w-[79vw] md:max-w-[95vw] h-[94vh] md:h-[90vh] max-h-[94vh] bg-bkg dark:bg-content"></div>
    ),
  }
);
const StudentMessages = loadable(
  () => import("./pages/StudentPages/StudentMessages"),
  {
    fallback: (
      <div className="md:min-w-[79vw] md:max-w-[95vw] h-[94vh] md:h-[90vh] max-h-[94vh] bg-bkg dark:bg-content"></div>
    ),
  }
);
const StudentPortefeuille = loadable(
  () => import("./pages/StudentPages/StudentPortefeuille"),
  {
    fallback: (
      <div className="md:min-w-[79vw] md:max-w-[95vw] h-[94vh] min-h-max md:h-[90vh] max-h-[94vh] bg-bkg dark:bg-content"></div>
    ),
  }
);
const StudentReservations = loadable(
  () => import("./pages/StudentPages/StudentReservations"),
  {
    fallback: (
      <div className="md:min-w-[79vw] md:max-w-[95vw] h-[94vh] md:h-[90vh] max-h-[94vh] bg-bkg dark:bg-content"></div>
    ),
  }
);
const StudentProfil = loadable(
  () => import("./pages/StudentPages/StudentProfil"),
  {
    fallback: (
      <div className="md:min-w-[79vw] md:max-w-[95vw] h-[94vh] md:h-[90vh] max-h-[94vh] bg-bkg dark:bg-content"></div>
    ),
  }
);

//Common
const SessionPage = loadable(() => import("./pages/SessionPages/SessionPage"), {
  fallback: <div className="w-full h-[90vh] bg-bkg dark:bg-content"></div>,
});

//Admin
const AdminCours = loadable(
  () => import("./pages/AdministratorPages/AdminCours"),
  {
    fallback: <div className="h-[90vh] w-screen bg-bkg dark:bg-content"></div>,
  }
);
const AdminPortfeuille = loadable(
  () => import("./pages/AdministratorPages/AdminPortefeuille"),
  {
    fallback: <div className="w-screen h-screen bg-bkg dark:bg-content"></div>,
  }
);
const AdminProfessors = loadable(
  () => import("./pages/AdministratorPages/AdminProfessors"),
  {
    fallback: <div className="h-[90vh] w-screen bg-bkg dark:bg-content"></div>,
  }
);
const AdminStatistics = loadable(
  () => import("./pages/AdministratorPages/AdminStatistics"),
  {
    fallback: (
      <div className="md:min-w-[79vw] md:max-w-[95vw] h-[94vh] md:h-[90vh] max-h-[94vh] bg-bkg dark:bg-content"></div>
    ),
  }
);
const AdminSubjects = loadable(
  () => import("./pages/AdministratorPages/AdminSubjects"),
  {
    fallback: <div className="h-[90vh] w-screen bg-bkg dark:bg-content"></div>,
  }
);
const AdminCourseDetails = loadable(
  () => import("./pages/AdministratorPages/AdminCourseDetails"),
  {
    fallback: <div className="h-[90vh] w-screen bg-bkg dark:bg-content"></div>,
  }
);
const AdminProfessorDetails = loadable(
  () => import("./pages/AdministratorPages/AdminProfessorDetails"),
  {
    fallback: <div className="h-[90vh] w-screen bg-bkg dark:bg-content"></div>,
  }
);
const AdminSubjectDetails = loadable(
  () => import("./pages/AdministratorPages/AdminSubjectDetails"),
  {
    fallback: <div className="h-[90vh] w-screen bg-bkg dark:bg-content"></div>,
  }
);

function App() {
  const { user, userType, isAuthenticated } = useContext(AuthContext);

  return (
    <DiscussionContextProvider user={user}>
      <SessionContextProvider user={user}>
        <Router>
          <NavBar />
          <div className="bg-bkg dark:bg-content w-screen flex flex-row flex-1 overflow-y-hidden scrollbar-hide min-h-[90vh]">
            <ToastContainer />
            <div className="flex ">
              <Sidebar />
            </div>
            <div className="w-full flex-grow h-max">
              <Routes>
                {/* <Route path="/" element={<ProfessorDiscussion/>} /> */}

                <Route path="/" element={<Home />} />
                <Route path="/joinus" element={<Preregistration />} />
                <Route path="/signupstudent" element={<SignUpStudent />} />
                <Route path="/signupprof" element={<SignUpProf />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/changepassword" element={<ChangePassword />} />
                <Route path="/professors" element={<AllProfs />} />
                <Route path="/professors/:profId" element={<ProfDetails />} />
                <Route path="/subjects" element={<Subjects />} />
                <Route path="/courses" element={<Courses />} />
                <Route
                  path="/profs-by-subject/:subjectId"
                  element={<ProfsBySubject />}
                />
                <Route path="/courses/:courseId" element={<CourseDetails />} />

                <Route path="/conditions" element={<Conditions />} />
                <Route path="/conditions-ventes" element={<ConditionsVentes />} />

                {/* Sidebar Routes */}
                {/*Prof*/}
                <Route
                  path="/professor/calendar"
                  element={
                    isAuthenticated && userType === UserType.Professor ? (
                      <ProfCalendrier />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/professor/courses"
                  element={
                    isAuthenticated && userType === UserType.Professor ? (
                      <ProfCourses />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/professor/discussions"
                  element={
                    isAuthenticated && userType === UserType.Professor ? (
                      <ProfessorDiscussion />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/professor/wallet"
                  element={
                    isAuthenticated && userType === UserType.Professor ? (
                      <ProfPortefeuille />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/professor/profile"
                  element={user ? <ProfessorProfil /> : <SignIn />}
                />
                <Route
                  path="/professor/session/:roomID"
                  element={
                    isAuthenticated && userType === UserType.Professor ? (
                      <SessionPage />
                    ) : (
                      <SignIn />
                    )
                  }
                />

                {/*Student*/}
                {/* <Route
                                    path="/student/invoices"
                                    element={
                                        user &&
                                        user.type === UserType.Student ? (
                                            <StudentFactures />
                                        ) : (
                                            <SignIn />
                                        )
                                    }
                                /> */}
                <Route
                  path="/student/favorites"
                  element={
                    isAuthenticated && userType === UserType.Student ? (
                      <StudentFavorites />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/student/discussions"
                  element={
                    isAuthenticated && userType === UserType.Student ? (
                      <StudentMessages />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/student/wallet"
                  element={
                    isAuthenticated && userType === UserType.Student ? (
                      <StudentPortefeuille />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/student/profile"
                  element={user ? <StudentProfil /> : <SignIn />}
                />
                <Route
                  path="/student/reservations"
                  element={
                    isAuthenticated && userType === UserType.Student ? (
                      <StudentReservations />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/student/session/:roomID"
                  element={
                    isAuthenticated && userType === UserType.Student ? (
                      <SessionPage />
                    ) : (
                      <SignIn />
                    )
                  }
                />

                {/*Admin*/}
                <Route
                  path="/admin/statistics"
                  element={
                    isAuthenticated && userType === UserType.Admin ? (
                      <AdminStatistics />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/admin/professors"
                  element={
                    isAuthenticated && userType === UserType.Admin ? (
                      <AdminProfessors />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/admin/professors/:professorId"
                  element={
                    isAuthenticated && userType === UserType.Admin ? (
                      <AdminProfessorDetails />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/admin/wallet"
                  element={
                    isAuthenticated && userType === UserType.Admin ? (
                      <AdminPortfeuille />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/admin/courses"
                  element={
                    isAuthenticated && userType === UserType.Admin ? (
                      <AdminCours />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/admin/courses/:courseId"
                  element={
                    isAuthenticated && userType === UserType.Admin ? (
                      <AdminCourseDetails />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/admin/subjects"
                  element={
                    isAuthenticated && userType === UserType.Admin ? (
                      <AdminSubjects />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route
                  path="/admin/subjects/:subjectId"
                  element={
                    isAuthenticated && userType === UserType.Admin ? (
                      <AdminSubjectDetails />
                    ) : (
                      <SignIn />
                    )
                  }
                />
                <Route path="*" element={<NotFound />} />
                {isAuthenticated && userType !== UserType.professor && (
                  <>
                    <Route path="/professor/*" element={<NotFound />} />
                  </>
                )}
                {isAuthenticated && userType !== UserType.Student && (
                  <>
                    <Route path="/student/*" element={<NotFound />} />
                  </>
                )}
                {isAuthenticated && userType !== UserType.Admin && (
                  <>
                    <Route path="/admin/*" element={<NotFound />} />
                  </>
                )}
              </Routes>
            </div>
          </div>
          <Footer />
        </Router>
      </SessionContextProvider>
    </DiscussionContextProvider>
  );
}

export default App;
