import axios from "axios";
import { API_URL } from '../config/config';

class ReservationService {
  constructor() {
    this.baseUrl = API_URL;
  }

  async createReservation(reservationData) {
    try {
      const response = await axios.post(
        `${this.baseUrl}/reservations`,
        reservationData
      );
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }

  async getReservationById(idReservation) {
    try {
      const response = await axios.get(
        `${this.baseUrl}/reservations/${idReservation}`
      );
      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error fetching reservation by ID :", error);
      throw error;
    }
  }

  async getReservationsByProfessorId(idProfessor) {
    try {
      const response = await axios.get(
        `${this.baseUrl}/reservations/professor/${idProfessor}`
      );
      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error fetching reservation by idProfessor :", error);
      throw error;
    }
  }

  async getReservationsByStudentId(idStudent) {
    try {
      const response = await axios.get(
        `${this.baseUrl}/reservations/student/${idStudent}`
      );

      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error fetching reservation by idStudent :", error);
      throw error;
    }
  }

  async changeReservationDate(idReservation, updatedReservationData) {
    try {
      const response = await axios.patch(
        `${this.baseUrl}/reservations/${idReservation}/date`,
        updatedReservationData
      );
      return response.data;
    } catch (error) {
      console.error("Error changing date reservation by ID:", error);
      throw error;
    }
  }

  async changeReservationState(
    idReservation,
    newState,
    oldDateTo,
    oldDispoToReturn,
    bool
  ) {
    try {
      const response = await axios.patch(
        `${this.baseUrl}/reservations/${idReservation}/state`,
        { newState, oldDateTo, oldDispoToReturn, bool }
      );
      return response.data;
    } catch (error) {
      console.error("Error changing state reservation by ID:", error);
      throw error;
    }
  }

  async changeReservationPaymentState(idReservation, newPaymentState) {
    try {
      const response = await axios.patch(
        `${this.baseUrl}/reservations/${idReservation}/payment-state/${newPaymentState}`
      );
      return response.data;
    } catch (error) {
      console.error("Error changing payment state reservation by ID:", error);
      throw error;
    }
  }

  async deleteReservation(idReservation) {
    try {
      const response = await axios.delete(
        `${this.baseUrl}/reservations/${idReservation}`
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting reservation by ID:", error);
      throw error;
    }
  }
}

export default ReservationService;
